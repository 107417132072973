div.alert {

  padding-left: .7em;

  ul {
    padding-left: 0px;
    margin-left: 1em;
    list-style-type: square;
  }

}