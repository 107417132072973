section.auth-register {

  div.container-fluid {

    div.header {
      text-align: center;
    } //  div.header

    @include screen-size(screen-sm) {
      width: 66%;
    } //  @include screen-size(screen-sm)

  } //  div.container-fluid

} //  section.auth-register