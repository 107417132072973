#cloverfield-side {
	margin-top: 0;
}

@media screen and (min-width: 300px) and (max-width:767px) {
    #cloverfield-side {
	margin-top: 1em;
    }
}

/* Die 2 Suchdinger nach unten wenn die cloverfield werbung oben angezeigt wird */
/* div.main-searches {

    @include screen-size(screen-lg) {
      top: 780px !important;
    }

}*/

div#cloverfield {
  margin-bottom: 2em;
}
