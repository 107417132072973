.talk-bubble-link-wrapper {
    display: none;
}

@include screen-size(screen-md) {

    .talk-bubble-link-wrapper {
        display: inline-block;
    }

    /* CSS talk bubble */
    .talk-bubble {
            margin: 40px;
          display: inline-block;
          position: relative;
            width: 160px;
            height: auto;
            background-color: lightyellow;
    }

    .border{
      border: 4px solid $color-primary;
    }

    .round{
      border-radius: 30px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;

    }

    /*Right triangle, placed bottom left side slightly in*/
    .tri-right.border.btm-left-in:before {
            content: ' ';
            position: absolute;
            width: 0;
            height: 0;
            left: 30px;
      right: auto;
      top: auto;
            bottom: -40px;
            border: 18px solid;
            border-color: $color-primary transparent transparent $color-primary;
    }

    .tri-right.btm-left-in:after{
            content: ' ';
            position: absolute;
            width: 0;
            height: 0;
            left: 38px;
      right: auto;
      top: auto;
            bottom: -20px;
            border: 12px solid;
            border-color: lightyellow transparent transparent lightyellow;
    }

    /* talk bubble contents */
    .talktext{
      padding: 1em;
            text-align: left;
      line-height: 1.5em;
    }

    .talktext p{
      /* remove webkit p margins */
      -webkit-margin-before: 0em;
      -webkit-margin-after: 0em;
      margin: 4px;
      text-decoration: underline;
      color: black;
    }

    .root-bubble {
        position: absolute;
        top: -38px;
        right: 30px;
    }

}
