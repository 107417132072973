/*  google-fonts included manually without @import url('...') because gulp complained about a timeout error
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* cyrillic */
@font-face {
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  src: local('Lobster'), local('Lobster-Regular'), url(https://fonts.gstatic.com/s/lobster/v14/w5HPj52zZZcV_MSSWRmf3xU.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  src: local('Lobster'), local('Lobster-Regular'), url(https://fonts.gstatic.com/s/lobster/v14/w5HPj52zZZcV_MSSUhmf3xU.woff2) format('woff2');
  unicode-range: U+0102-0103, U+1EA0-1EF1, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  src: local('Lobster'), local('Lobster-Regular'), url(https://fonts.gstatic.com/s/lobster/v14/w5HPj52zZZcV_MSSUxmf3xU.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  src: local('Lobster'), local('Lobster-Regular'), url(https://fonts.gstatic.com/s/lobster/v14/w5HPj52zZZcV_MSSXRmf.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

/* latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  src: local('Cabin Regular'), local('Cabin-Regular'), url(https://fonts.gstatic.com/s/cabin/v7/yQOMOX5hR0-6LTD879t-PQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~



/*  reset elements
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~



/*  headings
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
h1, h2, h3, h4, h5, h6 {
  line-height: 1.2 !important;
}


h1 {
  font-weight: 400;
  font-size: 20px;
  padding: 15px 15px 15px 15px;
  color: white;
  border-radius: 10px 10px 10px 10px;
  background: $color-header-footer;
  text-align: center;
  margin-bottom: .6em;
  
  @include screen-size(screen-sm) {
    font-size: 3.157em;
  } //  @include screen-size(screen-sm)
}

h2 {
  font-weight: normal;
  position: relative;
  text-shadow: 0 -1px rgba(0,0,0,0.6);
  font-size: 18px;
  line-height: 40px;
  background: $color-header-footer;
  color: white;
  border-radius: 0 10px 0 10px;
  margin-bottom: .4em;
  text-align: center;
  
  a {
    font-size: 1.4em;
  } //  a
  
  @include screen-size(screen-sm) {
    font-size: 2.369em;
  } //  @include screen-size(screen-sm)
  
}

h3 {
  //  margin: 1em 0 0.5em 0;
  font-weight: 600;
  position: relative;
  font-size: 16px;
  line-height: 40px;
  color: black;
  //text-transform: uppercase;
  border-bottom: 1px solid rgba(53,86,129, 0.3);
  text-align: center;
  
  @include screen-size(screen-sm) {
    font-size: 1.777em;
  } //  @include screen-size(screen-sm)
}

h4 {
  margin: 1em 0 0.5em 0;
  font-weight: 600;
  //font-family: 'Titillium Web', sans-serif;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #788699;
  //font-family: 'Muli', sans-serif;
  
  @include screen-size(screen-sm) {
    font-size: 1.333em;
  } //  @include screen-size(screen-sm)
}

h5 {
  font-size: 12px;
  
  @include screen-size(screen-sm) {
    font-size: 1em;
  } //  @include screen-size(screen-sm)
}

h6 {
  font-size: 10px;
  
  @include screen-size(screen-sm) {
    font-size: .75em;
  } //  @include screen-size(screen-sm)
}

body {
  color: black;
  font-family: "Helvetica Neue", Helvetica, Sans-Serif;
}
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
