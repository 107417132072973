aside.sidebar-left {

  margin-right: 0px; //  override margin of the template-designer

  @include screen-size(screen-sm) {
    margin-right: 30px; // set margin back to its original value
  } //  @include screen-size(screen-sm)

  #logo-in-sidebar {
    margin-bottom: 2em;
    
    img {
      padding: .7em;
    } //  img
  } //  #logo-in-sidebar
  
  div.header h5 {
    font-size: 1.2em;
    color: $color-primary;
    @include border-header;
  } //  div.header h5

  div.header h3 {
    color: $color-primary;
  } //  div.header h3

  .nav-tabs.nav-stacked.nav-coupon-category {

    
    @include screen-size(screen-sm) {
      font-size: .7em;
    } //  @include screen-size(screen-sm)

    @include screen-size(screen-md) {
      font-size: 1em;
    } //  @include screen-size(screen-sm)

    
    @include box-style-one;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 20px/50px;


    li a {
      background: inherit; //  reset background
      border-bottom: 1px dotted $color-header-footer;
      font-weight: bold;
	  overflow: hidden;
      
      i {
        background: $color-header-footer;
        color: $color-box;
      } //  /i
    } //  /li a
    
    li:first-child{
      border-top-left-radius: .7em;
      border-top-right-radius: .7em;

      a {
        border-top-left-radius: .7em;
        border-top-right-radius: .7em;

        i {
          border-top-left-radius: .7em;
        }
      }
    }

    li:last-child{
      border-bottom-left-radius: .7em;
      border-bottom-right-radius: .7em;

      a {
        border-bottom-left-radius: .7em;
        border-bottom-right-radius: .7em;
        border-bottom: none;

        i {
          border-bottom-left-radius: .7em;
        }
      }
    }

  } //  .nav-tabs.nav-stacked.nav-coupon-category

  .nav-tabs.nav-stacked.nav-coupon-category > li > a {
    font-size: 1.1em;
  }

} //  aside.sidebar-left


aside.sidebar-left.suche {

  margin-top: 1em;
  
  @include screen-size(screen-sm) {
    margin-top: 0;
  } //  @include screen-size(screen-sm)
  
  .city {
    .submit-button {
      margin-top: .7em;
    } //  .submit-button
  } //  city

  .umkreis {
    margin-top: 1em;
  } //  .umkreis
} //  aside.sidebar-left.suche


.sidebar-box {
  @include box-style-one;
  padding-top: .2em;
  padding-bottom: .5em;
  
  .icon-list-rating {
    text-align: left;

    @include screen-size(screen-sm) {
      font-size: 3em;
    } //  @include screen-size(screen-sm)

  } //  .icon-list-rating
  



  
} //  .sidebar-box


.thumb-list > li .thumb-list-item-caption .thumb-list-item-title {
  @include screen-size(screen-sm) {
    font-size: 1em;
  } //  @include screen-size(screen-sm)

  @include screen-size(screen-md) {
    font-size: 1.2em;
  } //  @include screen-size(screen-sm)

} //  .thumb-list > li .thumb-list-item-caption .thumb-list-item-title
