//  border-box for all elements
*, *:before, *:after {
  box-sizing: border-box;
}



// responsive images
img { max-width: 100%; height: auto; }



/*  show-and-hide-elements
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.hide {
  display: none;
} //  .hide

.show {
  display: block;
} //  .show
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~



/*  media-queries
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
$screen-sm: 768px; //  smallest tablets
$screen-md: 992px; //  mid tablets
$screen-lg: 1200px; //  large tablets

@mixin screen-size($media) {
  @if $media == screen-sm {
    @media only screen and (min-width: $screen-sm) { @content; }
  }
  @else if $media == screen-md {
    @media only screen and (min-width: $screen-md) { @content; }
  }
  @else if $media == screen-lg {
    @media only screen and (min-width: $screen-lg) { @content; }
  }
  @else {
    @media only screen and (min-width: $media) { @content; }
  }
}
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~



/*  colors
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
$color-primary: #9B2139;
$color-text: #333;
$color-main-background: #B4BAD2;
$color-header-footer: #373A47;
$color-box: #fffce1;
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~



/*  padding, border, margin
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
$padding-box: 1em;
$margin-between-boxes: 2em;

@mixin border-header() {
  border-bottom: .1em solid black;
}
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~



/*  box-styles
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
@mixin box-style-one() {
  //  background: $color-box;
  background: #FFFFFF;
  border-top-left-radius: .7em;
  border-top-right-radius: .7em;
  border-bottom-left-radius: .7em;
  border-bottom-right-radius: .7em;
  //  border-radius: 20px/50px;
  padding: $padding-box;
  margin-bottom: $margin-between-boxes;
}

@mixin box-style-two() {
  background: $color-box;
  //  border-radius: 35px/200px;
  background: #FFFFFF;
  border-top-left-radius: .7em;
  border-top-right-radius: .7em;
  border-bottom-left-radius: .7em;
  border-bottom-right-radius: .7em;
  padding: $padding-box;
  margin-bottom: $margin-between-boxes;
}

@mixin box-style-border() {
  border: 2px solid #9B2139;
  border-radius: .7em;
  box-shadow: 2px 2px 5px #888;
}
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
