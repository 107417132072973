header {

.navigation-menu {
    position: fixed;
    z-index: 1000;
    top: 20px;
    left: 10px;
    display: none;

    @include screen-size(500px) {
      display: inline-block;
    } //  @include screen-size(500px)
  
  @include screen-size(screen-sm) {
    top: 55px;
    left: 55px;
  } //  @include screen-size(screen-sm)


  .button-navigation-menu {
    position: relative;
    background: $color-primary;
    font-size: 150%;
    padding: .5em;
    border-radius: 0.7em;
  } //  /button-navigation-menu



  .test-navigation {
    position: relative;
    z-index: 10000;
    top: 15px;
    left: 15px;
    width: 0px;
    height: 0px;
    background: $color-header-footer;
    color: $color-box;
    list-style: none;
    padding: .7em;
    margin: 0;
    border-radius: 0.7em;

    visibility: hidden;
    //  transition: all .5s ease-out;

    &.active {
      visibility: visible;
      width: 100%;
      height: 100%;
      opacity: 0.8;

      @include screen-size(1450px) {
        opacity: 1;
      } //  @include screen-size(1450px)

    }

    
    &:hover {
      opacity: 1;
    } //  &:hover

    li {
      padding-bottom: .7em;
      
      i {
        width: 2em;
        color: $color-box;
      } //  /i

      &.list-item-entwicklung {
        border-bottom: 1px solid $color-primary;
        margin-bottom: .7em;
      } //  /.login-logout

      &.list-item-facebook {
        border-top: 1px solid $color-primary;
        padding-top: .7em;
      }

      &.list-item-german {
        border-top: 1px solid $color-primary;
        padding-top: .7em;
      }


    } //  /li

  } //  /.test-navigation

} //  .navigation-menu

} //  /header