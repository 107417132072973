footer#main-footer {
  $footer-color: #333;

  background: $footer-color;
  margin-top: 0px !important;
  
  h4 {
    color: $color-primary;
  } //  h4
  
  .fa-facebook {
    background: #3b5998;
  } //  .fa-facebook
  
  .footer-top-area {
    background: $footer-color;
    padding-top: 1em;
  } //  /.footer-top-area

  h3 {
    text-transform: none;
    color: white;
    font-weight: normal;
    margin-top: 0;
  } //  h3
  
  .indicates-required { /*  Required-Anzeige nach Rechts floaten */
    float: right;
    color: #9B2139;
    margin-bottom: 14px;
  }

  .asterisk { /*  Farbe von dem Stern ändern */
    color: #9B2139;
  }

  #mc_embed_signup {  /*  Schriftfarbe im Formular */
    color: white;
  }
  
  .logo {
    max-width: 230px;
  } //  .logo

}