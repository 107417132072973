.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #999
}

.tt-menu {
  cursor: pointer;
  width: 330px;
  margin-top: 4px;
  padding: 4px 0;
  background-color: $color-header-footer;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-shadow: 0 5px 10px rgba(0,0,0,.2);
}

.tt-suggestion {
  padding: 3px 20px;
  line-height: 24px;

  &:hover {
    color: #fff;
    /*background-color: #0097cf;*/
    background-color: $color-primary;
  }

}

.tt-suggestion p {
  margin: 0;
}

/** Added from this point */
.twitter-typeahead{
  width: 90%;
}

input.typeahead.tt-query{ /* This is optional */
  width: 300px !important;
}

@media screen and (min-width: 300px) and (max-width:480px) {


  #mainsearch .col-xs-3 {
    padding-left: 0;
  }

  input.form-control:nth-child(2) {
    font-size: 1em;
    width: 160px;
  }

  .tt-menu {
    width: 218px;
  }
}