.text_background_white {
    margin-right: 1em;
    margin-left: 1em;

    @include screen-size(screen-md) {
        margin-right: 34px;
        margin-left: 0;
    }

    background: #FFF;
    padding: 1em;
    margin-bottom: 2em;
    border-radius: .7em;
}

.margin_right_kacheln {
    margin-right: 1em;
    margin-left: 1em;

    @include screen-size(screen-md) {
        margin-right: 34px;
        margin-left: 0;
    }
}

.online_escape_game_name {
    margin-top: 1em !important;
    margin-bottom: 1em !important;

    @include screen-size(screen-md) {
        margin-top: 0em !important;
    }
}
