body {
  padding-top: 4px;
  background: $color-header-footer;
}

main {

  background: $color-main-background;
  background: url("../img/patterns/grey_wash_wall.png");

  //  padding-top: 2em; //  padding-top from main to header
  padding-bottom: 2em; //  padding-bottom from main to footer

  h2 {
    @include border-header;
  } //  h2
  
  div.tabbable {

    //  review-stars
    ul.icon-group.icon-list-rating {
      margin-left: 0px;
    } //  ul.icon-group.icon-list-rating

  } //  div.tabbable

  form {

    a.btn.btn-primary {
      margin-top: 1em; //  add space to google-recaptcha
    } //  a.btn.btn-primary

  } //  form


  .add-branch-reply {
    display: none; //  hide the form on startup, we will show it with javascript

    .eingeloggt {
      clear: both;
    } //  .eingeloggt
    
    .review-add {
      margin-top: .7em;
    } //  /.review-add
  }
  
  .review-add {
    margin-top: 1.2em;
  } //  .review-add

  .add-post-comment-reply {
    display: none; //  hide the form on startup, we will show it with javascript
  } // .add-post-comment-reply

  div.form-group.textarea-ckeditor { //  the div of the form template textarea
    margin-top: 1em; //  margin to the bottom submit button/link
    
    label {
      width: 100%;
    } //  label
  }


  #post-comment-reply-label {
    width: 100%;
  } //  #post-comment-reply-label

  div.form-group.rating { //  the div of the review textarea
    margin-bottom: 0px; //  margin to the bottom submit button/link
  }

  div.g-recaptcha {
    margin-bottom: 1em;
  }

  a.comment-reply {
    margin-right: .5em;

    display: block;

    @include screen-size(screen-sm) {
      display: inline;
    } //  @include screen-size(screen-sm)

  }

  .product-footer {
    p {
      margin: 0;
      color: $color-primary;
    }
  }

  .product-location {
    font-size: 1.2em;
    padding-top: 1.2em;
    color: #313131;

    i {
      color: $color-primary;
    }
  }

  .main-search, .main-search-2 {
    
    
    margin-top: 2em;
    border-radius: .7em;
    box-shadow: 4px 4px 10px #888;
    padding-right: .7em; //  padding-right to the button

    opacity: 0.9;

    .search-area-division-input {
      width: 300px;
    }
  }

  .main-search, .main-search-2 {
    margin-top: .3em;
    overflow: visible; //  otherwise typeahead-dropdown doesn't show
    position: relative;

  }

  .main-search-2 {
    z-index: 100;
  }

  .main-search {
    z-index: 1000;
  }

  div.main-searches {

    @include screen-size(screen-lg) {
      width: 600px;
      margin: 0 auto; //  center the searches
      position: absolute;
      top: 70px;
      left: 137.5px;
    }

    margin-bottom: 2em;
  }

  .main-search-mobile-branch, .main-search-mobile-city {
    overflow: visible;
    position: relative;
  }

  .main-search-mobile-city {
    z-index: 101;
  }

  .main-search-mobile-branch {
    z-index: 100;
  }
  
  .paginator, .pagination {
    text-align: center;
    margin-bottom: 30px;
  }

  .paginator.top {
    margin-bottom: 0;
  } //  .paginator.top
  
  .pagination > .active > span {
    background: $color-primary;
    border-color: black;

    &:hover {
      background: $color-primary;
    }
  }

  .add-postcomment .form-group {
    margin-bottom: 0;
  } //  .add-postcomment


  
  .breadcrumb-wrapper.city {
    padding-right: 0;
  } //  .breadcrumb-wrapper.city
  
  .breadcrumb-wrapper, .h1-wrapper, .keine-bilder-wrapper {

    @include screen-size(screen-sm) {
      padding-left: 0;
      padding-right: 2em;
      //padding-left: 0;
      //padding-right: 30px;
    } //  @include screen-size(screen-sm)
  } //  .breadcrumb-wrapper

  .keine-bilder-wrapper
  {

    @include screen-size(screen-sm) {
      padding-right: 0;
    } //  @include screen-size(screen-sm)
    
    p {
      @include box-style-two;
      border: 1px solid $color-primary;
    } //  p

  } //  .keine-bilder-wrapper


  .breadcrumb {
    //  @include box-style-two;
    @include box-style-one;
    font-weight: bold;
    text-align: left;
    padding: .2em ($padding-box / 2);
    margin-bottom: .7em;
    //  margin-right: 0;
    font-size: .9em;

    //  margin-left: 15px;
    //  margin-right: 30px;

    @include screen-size(screen-sm) {
      font-size: 1.5em;
    } //  @include screen-size(screen-sm)



    .active {
      color: black;
    } //  .active

    > li + li:before {
      content: "\00BB";
      color: black;
      font-weight: bold;
    }
  } //  .breadcrumb
  
  .form-group, form > div.form-group.rating {
    margin-bottom: .5em;
  } //  .form-group
  
  #submit-form-link {
    margin-top: 0;
  } //  #submit-form-link
  
  .beschreibung {
    @include screen-size(screen-sm) {
      padding-right: 20px;
    } //  @include screen-size(screen-sm)
    //  @include box-style-one;

    .text {
      @include box-style-one;
    } //  .text
  } //  .beschreibung

  .reviews {

    @include screen-size(screen-sm) {
      padding-right: 20px;
    } //  @include screen-size(screen-sm)

    .content {


    } //  .content
  } //  .reviews
  
  #edit-profile-form {


    margin-top: 1em;

    .avatar {
      
      label {
        display: block;
      } //  /label
      
      img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
      } //  /img

    } //  /.avatar

    div {
      margin-bottom: 1em;
    } //  /div
  } //  /#edit-profile-form

  .comment-and-reply-wrap {
    padding: .7em;
    border: 2px solid #9B2139;
    border-radius: .7em;
    box-shadow: 2px 2px 5px #888;
    @include box-style-one;
    margin-top: 2em;
    
    
    //  font-size: .8em;
    
    .comment-author {
      float: none;

      margin-bottom: 0;
      
      @include screen-size(screen-sm) {
        float: left;
        margin-bottom: 1.1em;
      } //  @include screen-size(screen-sm)

      .image {
        display: inline;
        float: left;
        margin-right: .6em;
        
        @include screen-size(screen-sm) {
          display: block;
        } //  @include screen-size(screen-sm)
        
      } //  .image

      img {
        max-width: 70px;

        @include screen-size(screen-sm) {
          max-width: 100%;
        } //  @include screen-size(screen-sm)
        
      } //  img
      
      
      
      
    } //  .comment-author
    

    @include screen-size(screen-sm) {
      font-size: 1em;
    } //  @include screen-size(screen-sm)
    

    #comment-replies-list {
      margin-left: 0;
      padding-left: 1.5em;
      
      @include screen-size(screen-sm) {
        margin-left: 1.5em;
        padding-left: 3em;
      } //  @include screen-size(screen-sm)
      
    } //  #comment-replies-list
    

    h4 {
      color: black;
    } //  h4

    .comment-content {
      color: black;
      padding-bottom: 0;
    } //  .comment-content
    


  } //  /.comment-and-reply-wrap



  
  .comment {
    margin-bottom: 1em;
  } //  /.comment
  
  .comment-author {

    margin-bottom: 1.3em;

    .image {
      width: 70px;
      height: 70px;
    } //  /.image
    
    div {
      margin-top: .3em;
    } //  /div

  } //  /.comment-author

  .gesamt-rating-ul, .gesamt-rating-text {
    display: inline;
  } //  /.gesamt-rating-ul, .gesamt-rating-text

  


  .comment-content {
    color: lighten($color-box, 20);
  } //  /.comment-content


  .dropdown-form-room {
    margin-top: 2em;
    margin-bottom: 2em;
    width: 100%;
    color: black;


    select {
      color: black;
    }

    label {
      display: inline;
      font-size: 1.4em;
      font-weight: bold;
    } //  label
    
    #dropdown_room_reviews {

      @include screen-size(screen-sm) {
        display: inline !important;
        width: auto;
        margin-left: .4em;
        padding-left: 0;
      } //  @include screen-size(screen-sm)


    } //  select


  } //  /.dropdown-form-room

  //.ratings-branch {
  //  @include box-style-one;
  //} //  /.ratings-

  .box-owl-carousel {
    margin-bottom: 0;

    h2 {
      border-bottom: none;
    } //  h2

    
    .text-uc {
      background: none;
      text-transform: none;
    } //  .text-uc
    
    .bg-holder .bg-front {
      top: 50%;


      //@include screen-size(screen-sm) {
      //  top: 63%;
      //} //  @include screen-size(screen-sm)
      
      @include screen-size(screen-lg) {
        top: 68%;
      } //  @include screen-size(screen-sm)

      h2 {
        //  background: none;
      } //  h2
      
    } //  .bg-holder
  } //  .box-owl-carousel


}



.product-thumb {

  background: $color-box;
  background: #FFFFFF;


  img {

    @include screen-size(screen-lg) {
      max-height: none;
    } //  @include screen-size(screen-sm)
  } //  img
  

  margin-bottom: 2em;
  height: 70%;

  .product-inner {
    background: inherit;
    padding: .4em;
    
    @include screen-size(screen-sm) {
      padding: 1.2em;
    } //  @include screen-size(screen-sm)

    .icon-list-rating {

      //  font-size: 1.3em;
      margin-bottom: 0;
      font-size: 2em;

      @include screen-size(screen-sm) {
        font-size: 1em;
      } //  @include screen-size(screen-sm)
      
      
      @include screen-size(screen-md) {
        font-size: 2em;
      } //  @include screen-size(screen-sm)

    }
    //  /.icon-list-rating

    .product-title {
      color: black;
      font-weight: bold;

      font-size: 120%;
      border-bottom: 1px solid $color-primary;
      padding-bottom: .7em;
      margin: 0;

      @include screen-size(screen-sm) {
        margin-top: .7em;
        margin-bottom: .2em;
      } //  @include screen-size(screen-sm)

    } //  .product-title

    .product-desciption, .product-description {


      //  display: none;

      @include screen-size(screen-sm) {
        margin-top: .7em;
        font-size: 105%;
      } //  @include screen-size(screen-sm)

    }
    //  /.product-description

    .header {
      margin-bottom: 1em;
    }
    //  .header

    .product-location {
      border-top: .1em solid black;
      border-bottom: 0;
      padding-top: .8em;
      
      @include screen-size(screen-sm) {
        padding-top: 1.2em;
      } //  @include screen-size(screen-sm)
    }
    //  .product-location

    .product-meta {
      margin-top: 0;

      .product-actions-list {
        border-top: 0;
        margin-top: 0;
        font-size: 120%;
      } //  .product-actions-list
    } //  .product-meta
  } //  .product-inner


  .product-quick-view .popup-text {
    background: $color-primary;
    color: $color-box;

    &:hover {
      background: $color-header-footer;
    } //  /&:hover
  } //  /.product-quickview
} //  .product-thumb


.mfp-dialog-big {
  @include box-style-one;
} //  .mfp-dialog-big


.product-info.box {
  margin-top: 1em;
  
  @include screen-size(screen-sm) {
    margin-top: 0;
  } //  @include screen-size(screen-sm)
} //  .product-info.box


.product-info.box, .mfp-dialog-big {
  @include box-style-one;
  
  img {
    border-radius: 5px 5px 0 0;
    margin-bottom: .7em;
    border: 1px solid black;
  } //  img

  .icon-list-rating {
    font-size: 2em;
    text-align: center;
  } //  .icon-list-rating

  .basierend-auf {
    text-align: center
  } //  .basierend-auf
} //  .product-info.box

#map-canvas {
  border: 1px solid $color-header-footer;
  margin-bottom: .4em;
} //  #map-canvas

.product-info-two.box {
  @include box-style-one;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: .7em;

  h3 {
    margin-top: 0;
    text-transform: none;
  } //  h3
  

  
  .list {
    margin-top: .7em;

    li {
      margin-bottom: .2em;
    } //  li
  } //  .list
}

.ratings-branch {
  margin-bottom: $margin-between-boxes;

  @include screen-size(screen-sm) {
    padding-right: 20px;
  } //  @include screen-size(screen-sm)

  .rating-personal, .rating-atmosphere, .rating-puzzle {
    @include box-style-two;
  }

  .icon-list-rating {
    border-bottom: 1px solid black;
    padding-bottom: .2em;
  } //  .icon-list-rating

  .text-rating-personal {
    color: black;
    font-size: 1.5em;
    text-align: center;
    padding-top: .3em;
  } //  .text-rating-personal

  .icon {
    text-align: center;
    font-size: 6em;
    color: $color-header-footer;
  } //  .icon
} //  .ratings-branch


.add-review-box {
  @include box-style-one;

  color: black;
} //  .add-review-box

.add-branchreview {

  
  .form-group {
    margin-bottom: 1em;
    margin-top: 1em;
  } //  .form-group
  
  h5 {
    margin-top: 2.7em;
    font-size: 1.7em;
  } //  h5
  
  .rating-stars-wrap {
    margin-bottom: 2.3em;
  } //  .rating-stars-wrap
  
  label {
    display: block;
    font-size: 1.4em;
    font-weight: bold;
  } //  label

  .dropdown-room {


    label {
      display: inline !important;
    }

    select {
      display: inline !important;
      width: auto;


      @include screen-size(screen-sm) {
        max-width: 54%;
      } //  @include screen-size(screen-sm)

      @include screen-size(screen-md) {
        margin-left: .4em;
      } //  @include screen-size(screen-sm)

    }

    @include screen-size(screen-sm) {
      //  width: 50%;
    } //  @include screen-size(screen-sm)



  }

  .title {
    width: 100%;

    input {
      width: 100%;
    }
  }
  
  .review-add {
    margin-top: 2em;
  } //  button


} //  /.add-branchreview


.overlay-test {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  z-index: 100000;
  opacity: 0.7;
}

#loading-gif {
  text-align: center;
  
  img {
    width: 100px;
  } //  img
} //  #loading-gif

.quickview-detail-button {
  text-align: center;
} //  .quickview-detail-button

.post {


.post-inner {
  @include box-style-one;
  margin-bottom: 0;
  border-radius: 0;

  .post-title {

    color: $color-primary;
    text-transform: none;
    text-shadow: none;
    text-align: center;

    a {
      color: $color-primary;
    } //  a
  } //  .post-title
  
  .post-desciption {
      color: black;
  } //  .post-desciption
} //  .post-inner

} //  .post


.post-comment-textarea, .post-comment-reply-textarea {
  margin-bottom: 1em;
} //  .post-comment-textarea

.login-form, .register-form {
  margin-top: 1.3em;
  @include box-style-one;
  
  h3 {
    margin-top: 0;
  } //  h3
} //  .login-form


.mfp-close-btn-in .mfp-close {
  color: red;
  opacity: 1;
  font-size: 4em;
} //  .mfp-close-btn-in .mfp-close


.icon-list-rating {
  font-size: 2em;
  text-align: center;
  color: $color-primary;
} //  .icon-list-rating


ul.pagination {
  margin-bottom: 0;
} //  .pagination


#ajax-insert-one, .ajax-insert-one {
  text-align: center;

  img {
    width: 100px;
    height: 100px;
  } //  img
  
  .alert {
    margin-top: 1.2em;
  } //  .alert
  
  ul {
    text-align: left;
  } //  ul

} //  #ajax-insert-one



.comment .comment-inner .comment-title p:first-child {
  display: inline;
} //  .comment .comment-inner .comment-title p:first-child


#submit-form-link-comment-reply, #submit-form-link-comment-post, #submit-form-link-comment {
  margin-top: 0;
} //  #submit-form-link-comment-replyajax-insert-one

.alert {
  margin-top: 1em;
  margin-bottom: .2em;
} //  .alert


.main-search-mobile-city, .main-search-mobile-branch {

  $padding: .1em;
  margin-bottom: 0;
  border-radius: 4%;
  padding: $padding;
  margin-bottom: 1em;

  i {
    margin-left: $padding;
  } //  i
  
  .button {

    //width: 90%;
    padding-left: $padding;
    padding-right: $padding;
    text-align: center;


    @include screen-size(screen-sm) {
      padding: 0;
      margin-top: .7em;
      margin-right: .7em;
    } //  @include screen-size(screen-sm)
  } //  .button
  
  
  @include screen-size(screen-md) {
    display: none;
  } //  @include screen-size(screen-sm)
  
} //  .main-search-mobile-city


.main-search-mobile-branch {
  overflow: visible;
} //  .main-search-mobile-branch


.main-search-mobile-city {
  margin-top: 1.4em;
} //  .main-search-mobile-city


ul.pagination {
  font-size: .9em;
} //  ul.pagination

.post-comment-reply {
  display: block;
  
  @include screen-size(screen-sm) {
    display: inline;
  } //  @include screen-size(screen-sm)
  
} //  .post-comment-reply


.comment-review-rate {
  display: inline;
  
  @include screen-size(screen-sm) {
    display: block;
  } //  @include screen-size(screen-sm)
  
} //  .comment-review-rate


.comment-review-rate.single {
  float: right;
  
  @include screen-size(screen-sm) {
    float: none;
  } //  @include screen-size(screen-sm)
  
} //  .comment-review-rate.single


#personal-rating-text, #puzzle-rating-text, #atmosphere-rating-text {
  display: inline;
  
  @include screen-size(screen-sm) {
    display: block;
  } //  @include screen-size(screen-sm)
} //  #personal-text


.not-visible-sm {
  
  @include screen-size(screen-sm) {
    display: none;
  } //  @include screen-size(screen-sm)

  
} //  .not-visible-sm


.mfp-with-anim {
  
  img {
    margin-top: 1em;
    
    @include screen-size(screen-sm) {
      margin-top: 0;
    } //  @include screen-size(screen-sm)
    
  } //  img
  
  .detail-button {
    text-align: center;
  } //  .detail-button
} //  .mfp-with-anim


#three-tiles-root {
  margin-top: 1.3em;
} //  #three-tiles-root



#main-container {


  @include screen-size(400px) {
    //$padding: 5em;
    //padding-left: $padding;
    //padding-right: $padding;
  } //  @include screen-size(screen-sm)


  @include screen-size(500px) {
    //$padding: 7em;
    //padding-left: $padding;
    //padding-right: $padding;
  } //  @include screen-size(screen-sm)

  @include screen-size(600px) {
    //$padding: 9em;
    //padding-left: $padding;
    //padding-right: $padding;
  } //  @include screen-size(screen-sm)

  @include screen-size(700px) {
    //$padding: 12em;
    //padding-left: $padding;
    //padding-right: $padding;
  } //  @include screen-size(screen-sm)


  @include screen-size(screen-sm) {
    //$padding: 0;
    //padding-left: $padding;
    //padding-right: $padding;
  } //  @include screen-size(screen-sm)


  @include screen-size(screen-md) {
    //$padding: 15px;
    //padding-left: $padding;
    //padding-right: $padding;
  } //  @include screen-size(screen-sm)

} //  #main-container


.thumb-list.thumb-list-right > li img {

  @include screen-size(screen-sm) {
    float: left;
  } //  @include screen-size(screen-sm)

  @include screen-size(screen-lg) {
    float: right;
  } //  @include screen-size(screen-sm)

} //  .thumb-list.thumb-list-right > li img

.thumb-list > li .thumb-list-item-caption .thumb-list-item-title a {
  color: black;
}

.basierend-auf {
  a {
    color: black;
  }
}

.product-info.box, .mfp-dialog-big {
  @include screen-size(screen-sm) {
    margin-top: 0;
  } //  @include screen-size(screen-sm)
} //  .product-info.box, .mfp-dialog-big


//.product-thumb .product-inner .icon-list-rating {
//  @include screen-size(screen-sm) {
//    font-size: 1.4em;
//  } //  @include screen-size(screen-sm)
//
//  @include screen-size(screen-md) {
//    font-size: 2em;
//  } //  @include screen-size(screen-sm)
//} //  .product-thumb .product-inner .icon-list-rating


.bottom-room-city#owl-carousel {

  .icon-list-rating {
    
    //@include screen-size(screen-sm) {
    //  font-size: 1em;
    //} //  @include screen-size(screen-sm)
    //
    //
    //@include screen-size(screen-lg) {
    //  font-size: 2em;
    //} //  @include screen-size(screen-sm)
    
  }
  
} //  .bottom-room-city


.text-hero {
  @include screen-size(screen-sm) {
    font-size: 1em;
  } //  @include screen-size(screen-sm)
  
  @include screen-size(screen-md) {
    font-size: 2.6em;
  } //  @include screen-size(screen-sm)
} //  .text-hero


#ganz-deutschland-redet-darueber {
   @include box-style-one;
} //  #ganz-deutschland-redet-darueber

#was-genau-sind-live-escape-rooms {
  @include box-style-one;
} //  #was-genau-sind-live-escape-rooms

.p-ueberschrift {
  color: white;
} //  .p-ueberschrift


#owl-carousel-detail-button {
  background: $color-primary;
  color: white;
} //  #owl-carousel-detail-button


.box-style {
  @include box-style-one;

  margin-top: 2em;
  
  p {
    color: black;
  } //  p
} //  .box-style


.image img, .comment-author img {
  border: 1px solid black;
} //  .main .comment-and-reply-wrap .comment-author .image img



.wrapper-product-info {
  $padding: 1.6em;
  padding-left: $padding;
  padding-right: $padding;
} //  .product-info.box


.mfp-with-anim .wrapper  {
  $padding: 1.5em;
  padding-left: $padding;
  padding-right: $padding;
} //  .mfp-dialog


#submit-form-link-comment-room {
  margin-top: 0;
} //  submit-form-link-comment-room

.add-comment-wrap {
  @include box-style-one;
} //  .add-comment-wrap


.no-uppercase {
  text-transform: none;
  margin-top: 0;
} //  .no-uppercase

.icon-list-rating {
  color: $color-primary !important;
} //  .icon-list-rating


#heading-one-div {

  @include screen-size(screen-sm) {
    padding-left: 0;
    padding-right: 0;
  } //  @include screen-size(screen-sm)

  @include screen-size(screen-md) {
    padding-left: 15px;
    padding-right: 15px;
  } //  @include screen-size(screen-md)

} //  #heading-one-div


#dropdown_add_review_room {
  border: 1px solid #333;
  color: $color-primary;
} //  #dropdown_add_review_room


#coupon-agb {
  @include box-style-one;
  color: black;
  margin-top: 2em;
} //  #coupon-agb

#coupon-form {
  @include box-style-one;
  color: black;
  margin-top: 2em;
} //  #coupon-form


.required {
  color: $color-primary;
  font-weight: bold;
} //  .required


.reply-avatar, .comment-avatar {
  width: 70px;
  height: 70px;
} //  .reply-avatar, .comment-avatar


.city-text {
  @include box-style-one;
} //  .city-text


.room#heading-one-div {
  @include screen-size(screen-sm) {
    padding-left: 0;
    padding-right: 30px;
  } //  @include screen-size(screen-sm)
} //  .room#heading-one-div


.profile-form-wrapper {
  @include box-style-one;
  color: black;
  margin-top: 2em;
} //  .profile-form-wrapper


.branch-rooms {
  @include screen-size(screen-sm) {
    padding-right: 20px;
  } //  @include screen-size(screen-sm)
} //  .branch-rooms


.all-branches-in {
  @include screen-size(screen-sm) {
    margin-right: 20px;
  } //  @include screen-size(screen-sm)
} //  .all-branches-in


.mfp-content {
  margin-top: 2em;
} //  .mfp-content

#bookingkit-branch {
  padding-right: 0 !important;

  @include screen-size(screen-sm) {
    padding-right: 1.2em !important;
  } //  @include screen-size(screen-sm)

  .tab-content {
    padding: .3em;
    
    @include screen-size(screen-sm) {
      padding: 1.2em;
    } //  @include screen-size(screen-sm)
  } //  .tab-content
} //  .bookingkit

.bookingkit .tabbable ul li a {
  background: #000;
  color: #FFF;
} //  .bookingkit


.ursprung-wrapper {
  @include box-style-one;
  @include screen-size(screen-sm) {
    margin-right: 2.5em;
  } //  @include screen-size(screen-sm)

  line-height: 22px;

  h2:first-child {
    margin-top: 0;
  }

  h2:not(:first-child) {
    @include screen-size(screen-sm) {
      margin-top: 1.6em;
    } //  @include screen-size(screen-sm)
  }

  h2 a {
    font-size: 33px;
  }

  #motas {
    @include screen-size(screen-lg) {
      padding-top: 5.1em;
    } //  @include screen-size(screen-sm)
  } //  #motas

  figure {

    margin-bottom: .7em;

    @include screen-size(screen-sm) {
      margin-bottom: 1.3em;
    } //  @include screen-size(screen-sm)

    font-size: .9em;
  }

  i {
    color: #9B2139;
  }

}

.entwicklung-wrapper {
  @include box-style-one;
  @include screen-size(screen-md) {
    margin-right: 2.5em;
  } //  @include screen-size(screen-md)

  line-height: 22px;

  .figure-entwicklung {
    @include screen-size(screen-md) {
      float: left;
      width: 50%;
      clear: both;
      margin-right: 2em;
    } //  @include screen-size(screen-md)
  } //  .figure-entwicklung

  figure {
    margin-bottom: 1.6em;

    font-size: .9em;
  }

  figure:last-of-type {
    margin-bottom: 3em;
  }

  i {
    color: #9B2139;
  }

  h2#first-h2 {
    @include screen-size(screen-md) {
      margin-left: 11em;
    } //  @include screen-size(screen-md)

    @include screen-size(screen-lg) {
      margin-left: 13em;
    } //  @include screen-size(screen-lg)
  }

  #komischer-margin-left {
    @include screen-size(screen-md) {
      margin-left: 57%;
    } //  @include screen-size(screen-md)
  } //  #komischer-margin-left

} //  .entwicklung-wrapper


#working_hours {

  h3 {
    text-transform: none;
  } //  h3

  .list-table-header {
    margin-top: .4em;
  } //  .list-table-header

  dl {
    margin-bottom: .3em;
  } //  dl
} //  #working_hours

#working_hours_additional {
  padding-left: 1em;
} //  #working_hours_additional


#length {
  color: black;
  text-align: center;

  #length_text {
    padding-left: .2em;
  } //  #length_text
} //  #length


.product-info {
  h3 {
    margin-top: 1.1em;
  } //  h3
} //  .product-info


#bookingkit-room, #bookingkit-branch {

  padding-right: 1em;

  #fullcalendar-room {
    background: #FFF;
    padding: 1em;

    h2 {
      background: #FFF;
      color: #000;
    } //  h2
  } //  #fullcalendar-room

  .tab-content {
    h2 {
      background: #FFF;
      color: #000;
    } //  h2
  } //  .tab-content



} //  #bookingkit-room


.room-kachel-persons-and-price {
  color: $color-primary;
} //  .room-kachel-persons-and-price


.quellen {
  overflow: hidden;
} //  .ursprung-wrapper


#one-hour-left {
  @include screen-size(screen-sm) {
    padding-right: 1.2em;
  } //  @include screen-size(screen-sm)
} //  #one-hour-left


#andere-fillialen-von {
  //margin-bottom: .7em;
} //  #andere-fillialen-von


#kachel-button-buchen {

  font-size: 1.0em;

  margin-bottom: .6em;
} //  #kachel-button-buchen

#kachel-button-buchen-room {
  margin-top: .6em;
  margin-bottom: .6em;
  font-size: 1.5em;
} //  #kachel-button-buchen

#kachel-button-buchen-oben {
  margin-top: .8em;
} //  #kachel-button-buchen-oben

#one-hour-left {
  .product-page-meta-price {
    font-size: 100%;
  }
}

#tile-booking-wrapper {
  @include screen-size(screen-md) {
    margin-right: 2.5em;
  } //  @include screen-size(screen-md)
} //  #tile-booking-wrapper


#coupons-wrapper {

  @include box-style-one;

  @include screen-size(screen-md) {
    margin-right: 2.7em;
    margin-left: 1em;
  } //  @include screen-size(screen-md)
} //  #coupons-wrapper


#umkreissuche-button-wrapper {
  @include screen-size(screen-md) {
    width: 40%;
  } //  @include screen-size(screen-md)
} //  #umkreissuche-button-wrapper


#book-room-wrapper {
  @include box-style-one;

  @include screen-size(screen-md) {
    margin-right: 2.9em;
    margin-left: 1em;
  } //  @include screen-size(screen-md)
} //  #book-room-wrapper


#booking-room-highlights {
  list-style: none;
  
  i {
    padding-right: .4em;
    color: $color-primary;
  } //  i
} //  #booking-room-highlights


#booking-room-prices {
  list-style: none;
} //  #booking-room-prices


.bk-detail-content {



  .bk-detail-description {
    h2 {
      color: white;
    }
  }

}

#sidebar-pic-branch-city {
  //padding: .2em;
  padding: 0;
  border: 0;

  img {
    border-radius: .7em;
  }
}


/* Hide AddToAny vertical share bar when screen is less than 980 pixels wide */
@media screen and (max-width: 768px) {
    .a2a_floating_style.a2a_vertical_style { display: none; }
}

@media screen and (min-width: 768px) {
    .a2a_floating_style.a2a_default_style { display: none; }
}


.search_city {

  color: #fff;
  opacity: 0.9;
  overflow: visible; //  otherwise typeahead-dropdown doesn't show
  position: relative;
  z-index: 1000;

  .tt-menu {
    width: 200px;
  }
}

// Grauschleier von den Karussel-Bildern im Root
.bg-mask {
  opacity: 0.2 !important;
}

a.fa.fa-facebook.box-icon {
	background: #3b5998;
}
